import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        backgroundColor: 'white',
        borderRadius: 16
    }
}));


const UseSearch = () => {
    const classes = useStyles();

    return (
        <Grid container justify="center" border={3} className={classes.grid}>
            <Card>
                <Typography>Use Filters to search applicants.</Typography>
            </Card>

        </Grid>
    )
};

export default UseSearch;