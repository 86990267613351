import { Fragment, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Grid, Select, MenuItem, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    backgroundColor: 'white',
    borderRadius: 16
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 16
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 120,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));


const defaultFilterState = {
  "department": "micu",
  "hiringType": "short term",
  "experienceSelected": "0-1",
  "experience": ['0', '1'],
  "education": "student"
};

const filterReducer = (state, action) => {
  if (action.type === 'department') {
    return {
      "department": action.value,
      "hiringType": state.hiringType,
      "experience": state.experience,
      "experienceSelected": state.experienceSelected,
      "education": state.education
    }
  }

  else if (action.type === 'experience') {
    let range = action.value.split('-');
    return {
      "department": state.department,
      "hiringType": state.hiringType,
      "experience": range,
      "experienceSelected": action.value,
      "education": state.education,
    }
  }
  else if (action.type === 'hiringType') {
    return {
      "department": state.department,
      "hiringType": action.value,
      "experience": state.experience,
      "experienceSelected": state.experienceSelected,
      "education": state.education
    }
  }
  else if (action.type === 'education') {
    return {
      "department": state.department,
      "hiringType": state.hiringType,
      "experience": state.experience,
      "experienceSelected": state.experienceSelected,
      "education": action.value
    }
  }
  else return defaultFilterState;
};

const Home = (props) => {
  const classes = useStyles();
  const [filterState, dispatchFilterAction] = useReducer(filterReducer, defaultFilterState);

  const departmentHandler = (event) => {
    dispatchFilterAction({ "type": "department", "value": event.target.value });
  };

  const experienceHandler = (event) => {
    dispatchFilterAction({ "type": "experience", "value": event.target.value });

  };
  const typeOfHireHandler = (event) => {
    dispatchFilterAction({ "type": "hiringType", "value": event.target.value });

  };

  const educationHandler = (event) => {
    dispatchFilterAction({ "type": "education", "value": event.target.value });

  };

  const searchHandler = () => {
    props.onSelect(filterState);
  };

  const showAllHandler = () => {
    props.onShowAll();
  };

  return (
    <Fragment>
      <Grid container justify="center" border={3} className={classes.grid}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="department" focused>Choose Department</InputLabel>
          <Select
            onChange={departmentHandler}
            value={filterState.department}
            labelId="department"
            id="department"
            autoWidth={true}
          >

            <MenuItem value='micu'>MICU</MenuItem>
            <MenuItem value='radiology'>RADIOLOGY</MenuItem>
            <MenuItem value='ccu'>CCU</MenuItem>
            <MenuItem value='hd'>HD</MenuItem>
            <MenuItem value='ortho opd'>Ortho OPD</MenuItem>

          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="experience" focused>Experience Required</InputLabel>
          <Select
            onChange={experienceHandler}
            value={filterState.experienceSelected}
            labelId="experience"
            id="experience"
          >
            <MenuItem value='0-1'>0-1 Year</MenuItem>
            <MenuItem value='1-3'>1-3 Years</MenuItem>
            <MenuItem value='3-5'>3-5 Years</MenuItem>
            <MenuItem value='5-8'>5-8 Years</MenuItem>
            <MenuItem value='8-100'>8+ Years</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="typeOfHire" focused >Type of Hire</InputLabel>
          <Select
            onChange={typeOfHireHandler}
            value={filterState.hiringType}
            labelId="typeOfHire"
            id="typeOfHire"
          >
            <MenuItem value='short term'>Short Term</MenuItem>
            <MenuItem value='long term'>Long Term</MenuItem>
            <MenuItem value='permanent'>Permanent</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="education" focused >Hieghest Level Of Education</InputLabel>
          <Select
            onChange={educationHandler}
            value={filterState.education}
            labelId="education"
            id="education"
          >
            <MenuItem value='student'>Current Nursing Student</MenuItem>
            <MenuItem value='gnm'>GNM</MenuItem>
            <MenuItem value='anm'>ANM</MenuItem>
            <MenuItem value='pbbsc'>P.B.B.S.C Nursing</MenuItem>
            <MenuItem value='msc'>M.S.C Nursing</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container justify="center" border={3}  >
        <Grid item className={classes.button}>
          <Button color="primary" variant="contained" onClick={searchHandler}>Search</Button>
        </Grid>
        <Grid item className={classes.button}>
          <Button color="primary" variant="contained" onClick={showAllHandler}>Show All</Button>
        </Grid>
      </Grid>
    </Fragment >
  )
};

export default Home;